<template>
  <div>
    <b-form-group
      class="mb-2"
      v-if="posmCategoryData.type === ENUM.POSM_INPUT_TYPE.IMAGE"
      :label="`Vị trí hạng mục: ${posmCategoryData.position}`"
      :label-for="
        `${side}-${posmCategoryData.position}-form-categories-${posmCategoryDataIndex}`
      "
    >
      <b-form-file
        :id="
          `${side}-${posmCategoryData.position}-form-categories-${posmCategoryDataIndex}`
        "
        v-model="posmCategoryData.data"
        :state="Boolean(posmCategoryData.data)"
        accept="image/jpeg, image/png, image/gif"
        @input="fileChangeHandler"
        required
      ></b-form-file>
      <b-form-text>Hình cần có định dạng: jpg, png, gif.</b-form-text>
    </b-form-group>
    <b-form-group
      class="mb-2"
      v-else-if="posmCategoryData.type === ENUM.POSM_INPUT_TYPE.TEXT"
      :label="`Vị trí hạng mục: ${posmCategoryData.position}`"
      :label-for="
        `${side}-${posmCategoryData.position}-form-categories-${posmCategoryDataIndex}`
      "
    >
      <b-form-input
        :id="
          `${side}-${posmCategoryData.position}-form-categories-${posmCategoryDataIndex}`
        "
        v-model="posmCategoryData.data"
        required
      ></b-form-input>
    </b-form-group>
    <b-form-group
      class="mb-2"
      v-else-if="posmCategoryData.type === ENUM.POSM_INPUT_TYPE.FILE"
      :label="`Vị trí hạng mục: ${posmCategoryData.position}`"
      :label-for="
        `${side}-${posmCategoryData.position}-form-categories-${posmCategoryDataIndex}`
      "
    >
      <b-form-file
        :id="
          `${side}-${posmCategoryData.data.position}-form-categories-${posmCategoryDataIndex}`
        "
        v-model="posmCategoryData.data"
        :state="Boolean(posmCategoryData.data)"
        required
      ></b-form-file>
      <b-form-text>File cần có dung lượng không lớn hơn 1MB.</b-form-text>
    </b-form-group>
    <b-form-group
      class="mb-2"
      v-else-if="posmCategoryData.type === ENUM.POSM_INPUT_TYPE.DROPDOWN"
      :label="`Vị trí hạng mục: ${posmCategoryData.position}`"
      :label-for="`${side}-form-categories-${posmCategoryDataIndex}`"
    >
      <b-form-select
        :id="`${side}-form-categories-${posmCategoryDataIndex}`"
        v-model="posmCategoryData.data"
        :state="Boolean(posmCategoryData.data)"
        required
      ></b-form-select>
    </b-form-group>
  </div>
</template>
<script>
export default {
  name: 'POSMFileInput',
  props: {
    ENUM: {},
    posmCategoryData: {},
    posmCategoryDataIndex: {},
    side: {},
  },
  methods: {
    fileChangeHandler(file) {
      this.$set(this.posmCategoryData, 'data', file);
    },
  },
};
</script>
