<template>
  <KTCodePreview v-bind:title="'Báo cáo POSM'">
    <template v-slot:preview>
      <div>
        <b-form @submit="onSubmit" v-if="show" enctype="multipart/form-data">
          <b-row>
            <b-col cols="6">
              <b-form-group label="Khu vực:" label-for="input-area">
                <b-form-select
                  id="input-area"
                  v-model="form.areaId"
                  :options="areaOptions"
                  required
                  size="sm"
                ></b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Cửa hàng:" label-for="input-store">
                <b-form-select
                  id="input-area"
                  v-model="form.storeId"
                  :options="storeOptions"
                  :disabled="disableStoreSelection"
                  required
                  size="sm"
                ></b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group
                label="Chọn Campaign đã gắn cho cửa hàng:"
                label-for="input-campaign"
              >
                <b-form-select
                  id="input-campaign"
                  v-model="form.campaignId"
                  :options="campaignOptions"
                  :disabled="disableUserSelection"
                  required
                  size="sm"
                >
                  <template #first>
                    <b-form-select-option :value="null" disabled
                      >-- Hãy lựa chọn campaign đã gắn cho cửa hàng --
                    </b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group
                label="Người quản lý cửa hàng:"
                label-for="input-employee"
              >
                <b-form-select
                  id="input-employee"
                  v-model="form.employeeId"
                  :options="employeeOptions"
                  :disabled="disableUserSelection"
                  required
                  size="sm"
                ></b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group
                label="Chọn form bạn cần điền"
                label-for="input-form-uuid"
              >
                <b-form-select
                  id="input-form-uuid"
                  v-model="form.posmFormUuid"
                  :options="formOptions"
                  :disabled="disableUserSelection"
                  required
                  size="sm"
                >
                  <template #first>
                    <b-form-select-option :value="null" disabled
                      >-- Hãy lựa chọn form cần điền --
                    </b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>

          <div
            v-if="
              form.employeeId &&
                form.storeId &&
                form.posmFormUuid &&
                form.campaignId
            "
          >
            <h4>Bên trong:</h4>
            <b-row
              cols="12"
              v-for="(posmCategories, categoryIndex) of form.store.interior"
              v-bind:key="`interior-form-categories-${categoryIndex}`"
            >
              <b-col>
                <div class="shadow p-6 mb-8 bg-white rounded">
                  <h5>Hạng mục: {{ posmCategories.name }}</h5>

                  <div
                    v-for="(posmCategoryData,
                    posmCategoryDataIndex) of posmCategories.data"
                    v-bind:key="
                      `exterior-form-categories-${posmCategoryDataIndex}`
                    "
                    class="mb-6"
                  >
                    <PosmFillIn
                      :d-a-t-e-t-i-m-e_-f-o-r-m-a-t="DATETIME_FORMAT"
                      :e-n-u-m="ENUM"
                      :format-date="
                        formatDate(posmCategoryData.startDate, DATETIME_FORMAT)
                      "
                      :posm-category-data="posmCategoryData"
                      :posm-category-data-index="posmCategoryDataIndex"
                    />
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
          <div
            v-if="
              form.employeeId &&
                form.storeId &&
                form.posmFormUuid &&
                form.campaignId
            "
          >
            <h4>Bên ngoài:</h4>
            <b-row
              cols="12"
              v-for="(posmCategories, categoryIndex) of form.store.exterior"
              v-bind:key="`exterior-form-categories-${categoryIndex}`"
            >
              <b-col>
                <div class="shadow p-6 mb-8 bg-white rounded">
                  <h5>Hạng mục: {{ posmCategories.name }}</h5>

                  <div
                    v-for="(posmCategoryData,
                    posmCategoryDataIndex) of posmCategories.data"
                    v-bind:key="
                      `exterior-form-categories-${posmCategoryDataIndex}`
                    "
                    class="mb-6"
                  >
                    <PosmFillIn
                      :d-a-t-e-t-i-m-e_-f-o-r-m-a-t="DATETIME_FORMAT"
                      :e-n-u-m="ENUM"
                      :format-date="
                        formatDate(posmCategoryData.startDate, DATETIME_FORMAT)
                      "
                      :posm-category-data="posmCategoryData"
                      :posm-category-data-index="posmCategoryDataIndex"
                    />
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>

          <b-button
            type="submit"
            variant="primary"
            :disabled="!!isSubmitting"
            size="sm"
            >Lưu
          </b-button>
        </b-form>
      </div>
    </template>
  </KTCodePreview>
</template>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import ApiService from '@/core/services/api.service';
import SelectOption from '@/view/pages/posm/models/SelectOption';

import createFirstSelectionOption from '@/view/pages/posm/utils/createFirstSelectionOption';
import ENUM, { POSM_TYPE, STORE_TYPE } from '@/utils/enum';
import { cmdUrl } from '@/utils/apiUrl';
import { formatDate, getRemainingDays } from '@/utils/date';
import {
  DATETIME_FORMAT,
  FORM_DATA_POSM_SUBMIT,
  SUCCESS_RESPONSE_STATUS,
} from '@/utils/constants';
import { makeToastFaile, makeToastSuccess } from '@/utils/common';
import StoreFromServer from '@/view/pages/posm/models/StoreFromServer';
import POSMCategory from '@/view/pages/posm/models/POSMCategory';
import { cloneDeep, uniqBy } from 'lodash';
import POSMCategoryData from '@/view/pages/posm/models/POSMCategoryData';
import moment from 'moment/moment';
import PosmFillIn from '@/view/components/posm/PosmFillIn.vue';

function extractCategoriesAndTheirPositionData(data, isExterior) {
  let categories = data.data.Posms.filter(
    (posm) => posm.isExterior === isExterior,
  ).map(
    (posm) =>
      new POSMCategory(
        posm.PosmCategory.name,
        [],
        posm.PosmCategory.id,
        posm.PosmCategory.inputType,
      ),
  );
  categories = uniqBy(categories, 'id');
  categories.forEach((category, index, outerArr) => {
    for (const posm of data.data.Posms) {
      if (posm.PosmCategory.id === category.id) {
        if (posm.isExterior === isExterior) {
          outerArr[index].data.push(
            new POSMCategoryData(
              posm.name,
              null,
              posm.PosmCategory.inputType,
              posm.startDate,
              posm.endDate,
              posm.position,
              posm.id,
            ),
          );
        }
      }
    }
  });
  return categories;
}

function assignFormData(payload, generalPosition, outerKey, formData) {
  const categories = payload.store[generalPosition];
  for (const [categoryIndex, category] of categories.entries()) {
    const { data, ...rest } = category;
    for (const [categoryKey, categoryValue] of Object.entries(rest)) {
      formData.append(
        `${outerKey}[${generalPosition}][${categoryIndex}][${categoryKey}]`,
        categoryValue,
      );
    }
    if (data) {
      for (const [dataIndex, data] of data.entries()) {
        for (const [dataKey, dataValue] of Object.entries(data)) {
          formData.append(
            `${outerKey}[${generalPosition}][${categoryIndex}][data][${dataIndex}][${dataKey}]`,
            dataValue,
          );
        }
      }
    }
  }
}

export default {
  components: {
    PosmFillIn,
    KTCodePreview,
  },
  data() {
    return {
      ENUM,
      form: {
        areaId: null,
        storeId: null,
        employeeId: null,
        campaignId: null,
        posmFormUuid: null,
        store: {},
      },
      searchArea: '',
      areaOptions: [createFirstSelectionOption('khu vực')],
      storeOptions: [createFirstSelectionOption('cửa hàng')],
      employeeOptions: [createFirstSelectionOption('quản lý')],
      campaignOptions: [],
      formOptions: [],
      show: true,
      payload: null,
      isSubmitting: false,
      DATETIME_FORMAT,
    };
  },
  methods: {
    formatDate,
    fetchAreas() {
      ApiService.get(`/area/store-type/${STORE_TYPE.DDV}`)
        .then(({ data }) => {
          if (data.status === SUCCESS_RESPONSE_STATUS) {
            this.areaOptions = data.data.map(
              (item) => new SelectOption(item.name, item.id),
            );
            this.areaOptions.unshift(createFirstSelectionOption('khu vực'));
          }
        })
        .catch((err) => {
          if (!err.response) {
            this.makeToastFaile('Kiểm tra lại kết nối internet');
          } else {
            this.makeToastFaile(err.response.message);
          }
        });
    },
    fetchStores() {
      ApiService.get(`/stores?areaId=${this.form.areaId}&limit=10000&page=1`)
        .then(({ data }) => {
          if (data.status === SUCCESS_RESPONSE_STATUS) {
            this.storeOptions = data.data.list_store.map(
              (item) => new SelectOption(item.address, item.id),
            );
            this.storeOptions.unshift(createFirstSelectionOption('cửa hàng'));
          }
        })
        .catch((err) => {
          if (!err.response) {
            this.makeToastFaile('Kiểm tra lại kết nối internet');
          } else {
            this.makeToastFaile(err.message);
          }
        });
    },

    fetchEmployeesByStoreId() {
      ApiService.get(
        `/employees/get-by-store?employeeName=&storeId=${this.form.storeId}`,
      )
        .then(({ data }) => {
          if (data.status === SUCCESS_RESPONSE_STATUS) {
            this.employeeOptions = data.data.map((item) => {
              return new SelectOption(item.fullName, item.id);
            });
            this.employeeOptions.unshift(createFirstSelectionOption('quản lý'));
          }
        })
        .catch((err) => {
          if (!err.response) {
            this.makeToastFaile('Kiểm tra lại kết nối internet');
          } else {
            this.makeToastFaile(err.message);
          }
        });
    },
    fetchCampaignsByStoreId(params) {
      ApiService.query(cmdUrl.Campaign.root + '/stores/' + this.form.storeId, {
        params: params ? params : { ...this.apiParams },
      })
        .then(({ data }) => {
          if (data.status === SUCCESS_RESPONSE_STATUS) {
            this.campaignOptions = data.data.map((campaign) => {
              const now = moment().format(DATETIME_FORMAT);
              const formattedStartDate = formatDate(
                campaign.startDate,
                DATETIME_FORMAT,
              );
              const formattedEndDate = formatDate(
                campaign.endDate,
                DATETIME_FORMAT,
              );
              return new SelectOption(
                `${
                  campaign.name
                }. Từ: ${formattedStartDate} - Đến: ${formattedEndDate} (Còn: ${getRemainingDays(
                  now,
                  formattedEndDate,
                )} ngày)`,
                campaign.id,
              );
            });
          }
        })
        .catch((err) => {
          makeToastFaile(err.message);
        });
    },
    fetchPosmForms() {
      if (!this.form.storeId || !this.form.campaignId) {
        return;
      }
      ApiService.get(
        cmdUrl.POSMForm.root +
          '/store/' +
          this.form.storeId +
          '/campaign/' +
          this.form.campaignId,
      )
        .then(({ data }) => {
          if (data.status === SUCCESS_RESPONSE_STATUS) {
            this.formOptions = data.data.map((form) => {
              return new SelectOption(`${form.name}`, form.uuid);
            });
          }
        })
        .catch((err) => {
          makeToastFaile(err.message);
        });
    },
    fetchPosms() {
      if (!this.form.posmFormUuid) {
        return;
      }
      ApiService.get(
        cmdUrl.POSMForm.root + '/posm-info/' + this.form.posmFormUuid,
      )
        .then(({ data }) => {
          if (data.status === SUCCESS_RESPONSE_STATUS) {
            this.form.store = {};
            const interiorCategories = extractCategoriesAndTheirPositionData(
              data,
              POSM_TYPE.INTERIOR,
            );
            const exteriorCategories = extractCategoriesAndTheirPositionData(
              data,
              POSM_TYPE.EXTERIOR,
            );
            this.form.store = Object.assign(
              {},
              new StoreFromServer('', interiorCategories, exteriorCategories),
            );
          }
        })
        .catch((err) => {
          makeToastFaile(err.response ? err.response.message : 'Lỗi');
        });
    },
    appendToFormData() {
      const formData = new FormData();
      const payload = cloneDeep(this.form);
      for (const outerKey in payload) {
        formData.append(outerKey, payload[outerKey]);
        if (outerKey === FORM_DATA_POSM_SUBMIT.OUTER_KEY) {
          assignFormData.call(
            this,
            payload,
            FORM_DATA_POSM_SUBMIT.INTERIOR_CATEGORIES,
            outerKey,
            formData,
          );
          assignFormData.call(
            this,
            payload,
            FORM_DATA_POSM_SUBMIT.EXTERIOR_CATEGORIES,
            outerKey,
            formData,
          );
        }
      }
      return formData;
    },

    onSubmit(evt) {
      evt.preventDefault();
      const payload = this.appendToFormData();
      this.isSubmitting = true;
      ApiService.post('/posm-forms/submit', payload)
        .then(({ data }) => {
          if (data.status === SUCCESS_RESPONSE_STATUS) {
            makeToastSuccess('Tạo mới thành công');
            this.$router.push({ name: 'posm-form-overview' });
          }
        })
        .catch((err) => {
          makeToastFaile(err.response.data.message || 'Đã có lỗi xảy ra');
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
  },
  watch: {
    'form.areaId'() {
      this.fetchStores();
      this.form.storeId = null;
      this.form.employeeId = null;
    },
    'form.storeId'() {
      if (this.form.areaId !== null) {
        this.fetchEmployeesByStoreId();
        this.fetchCampaignsByStoreId();
      } else {
        this.form.employeeId = null;
      }
    },
    'form.campaignId'() {
      this.fetchPosmForms();
    },
    'form.posmFormUuid'() {
      this.fetchPosms();
    },
  },
  mounted() {
    this.fetchAreas();
  },
  computed: {
    disableStoreSelection() {
      return this.form.areaId === null;
    },
    disableUserSelection() {
      return this.form.areaId === null || this.form.storeId === null;
    },
  },
};
</script>
