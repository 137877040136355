<template>
  <div>
    <POSMInput
      :e-n-u-m='ENUM'
      side='exterior'
      :posm-category-data='posmCategoryData'
      :posm-category-data-index='posmCategoryDataIndex'
    />
    <div class='text-danger bold'>
      <p v-if='posmCategoryData.note && posmCategoryData.note.length > 0'>
        Ghi chú: {{ posmCategoryData.note }}
      </p>
      <p v-if='posmCategoryData.size && posmCategoryData.size.length > 0'>
        Kích thước: {{ posmCategoryData.size }}
      </p>
      <p
        v-if='
          posmCategoryData.startDate && posmCategoryData.startDate.length > 0
        '
      >
        Ngày bắt đầu:
        {{ formatDate }}
      </p>
      <p v-if='posmCategoryData.endDate && posmCategoryData.endDate.length > 0'>
        Ngày kết thúc:
        {{ formatDate }}
      </p>
    </div>
  </div>
</template>
<script>
import POSMInput from '@/view/components/posm/POSMInput.vue';

export default {
  name: 'PosmFillIn',
  components: { POSMInput },
  props: {
    DATETIME_FORMAT: {},
    ENUM: {},
    formatDate: {},
    posmCategoryData: {},
    posmCategoryDataIndex: {},
  },
};
</script>
